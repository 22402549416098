import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import DoctorImage from "../../assets/images/doctor.webp";
import { ReactComponent as Logo } from "../../assets/svgs/Logo.svg";
import { Input, PatientAuthButton, PatientInput } from "./Utilities";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { Account, Databases, ID, Query } from "appwrite";
import { client } from "../../appwrite/appwriteConfig";
import { toast, ToastContainer } from "react-toastify";

const EmployeePatientForm = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10,12}$/, "Phone number is invalid"),
    dob: Yup.string(),
    email: Yup.string().email().required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").max(32, "Password must not exceed 32 characters").required("Please Enter your password"),
    confirmPassword: Yup.string().min(8, "Password must be at least 8 characters").max(32, "Password must not exceed 32 characters").required("Please Enter your password"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [date, setDate] = useState();
  const [dateError, setDateError] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgImage, setOrgImage] = useState()
  const [loading, setLoading] = useState(false);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setValue, reset } = useForm(formOptions);
  const { errors } = formState;

  const params = useParams();
  const database = new Databases(client);
  const account = new Account(client);
  const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID || ""; //to deal with env variables not having type definitions
  const orgColID = process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID || "";
  const patientColID = process.env.REACT_APP_APPWRITE_PATIENT_COLLECTION_ID || "";

  useEffect(() => {
    if (params?.id) {
      const paramsArray = params?.id.split("-");
      const orgID = paramsArray[paramsArray.length - 1];
      database
        .listDocuments(dbID, orgColID, [Query.equal("$id", orgID)])
        .then((res) => {
          setOrgName(res?.documents?.[0]?.company_name);
          setOrgImage(res?.documents?.[0]?.image_id)
        })
        .catch((err) => console.log(err));
    }
  }, [params]);


  const submitForm = async (data) => {
    if (!date) {
      setDateError("Date is required");
      return;
    }
    if (data.password !== data.confirmPassword) {
      toast("Password and Confirm password must be the same");
      return;
    }
    setValue("dob", date);
    //Calc age
    let startDate = new Date();
    let endDate = new Date(date);
    const age = Math.abs(moment.duration(endDate - startDate).years());
    const finalData = { ...data, dob: date, age: age };


    let orgID;
    let userID;


    // get orgID
    let orgIDParamArray = params.id.split("-");
    orgID = orgIDParamArray[orgIDParamArray.length - 1]; 

    setLoading(true);
    //Save data to users collection
    try {
      const res = await account.create(ID.unique(), finalData.email, finalData.password, `${finalData.firstName} ${finalData.lastName}`);
      userID = res?.$id;
    } catch (error) {
      toast(error.message, { type: "error" });
      setLoading(false);
      return;
    }

    //Save data to patients collection
    try {
      const res = await database.createDocument(dbID, patientColID, ID.unique(), {
        first_name: finalData.firstName,
        last_name: finalData.lastName,
        gender: finalData.gender,
        weight: finalData.weight,
        height: finalData.height,
        phone_number: finalData.phoneNumber,
        email: finalData.email,
        blood_type: finalData.blood,
        age: finalData.age,
        userID: userID,
        orgID: orgID,
        dob: finalData.dob,
        isVerified: 0,
      });
      setLoading(false);
      toast("Registration successful", { type: "success" });
      reset();
    } catch (error) {
      toast(error.message, { type: "error" });
      setLoading(false);
      return;
    }

    // setLoading(false);
  };

  return (
    <div className="min-w-screen min-h-screen font-commiss flex ">
      {/* Image */}
      <div className="hidden md:block min-h-screen min-w-[350px] xl:min-w-[450px] relative z-10">
        <div className="h-full w-full absolute bg-red-400 opacity-[0.4] top-0 left-0 " />
        <div className="h-full w-full bg-no-repeat bg-center bg-cover " style={{ backgroundImage: `url(${DoctorImage})` }} />
      </div>

      {/* Form */}
      <div className="w-full h-full flex flex-col ">
        <div className="flex flex-col items-center">
          <Logo />
          <div className="text-[36px] font-bold">Welcome</div>
          {orgName ? <div className="mt-2 font-semibold">Register with {orgName}</div> : null}
          <div className="mt-2 text-[14px]">Feel free to be healthy today!</div>
        </div>

        <form className="mt-6 md:mt-4 pb-8" onSubmit={handleSubmit(submitForm)}>
          {/* <div className="flex flex-col items-center gap-y-5 lg:gap-y-10  px-2 xl:px-20"> */}
          <div className="flex flex-col justify-center items-center gap-y-5 lg:gap-y-10  px-2 ">
            <div className="w-full flex flex-col gap-y-5  items-center lg:items-start lg:flex-row justify-center md:gap-x-5 xl:gap-x-20  ">
              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">First Name</p>
                <PatientInput name="firstName" type="text" placeholder="someone" width="w-[320px]" height="h-[45px]" register={register} />
                <p className="text-[13px] mt-2 text-red-400">{errors?.firstName?.message}</p>
              </div>
              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">Last Name</p>
                <PatientInput name="lastName" type="text" placeholder="someone" width="w-[320px]" height="h-[45px]" register={register} />
                <p className="text-[13px] mt-2 text-red-400">{errors?.lastName?.message}</p>
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-5  items-center lg:items-start lg:flex-row justify-center md:gap-x-5 xl:gap-x-20 ">
              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">Date of Birth</p>
                <input name="date" value={date} type="date" placeholder="someone@doci.com" width="w-[320px]" height="h-[45px]" className="w-[320px] h-[45px] inputField pl-2 mt-1 " onChange={(e) => setDate(e.target.value)} />
                <p className="text-[13px] mt-2 text-red-400">{dateError}</p>
              </div>

              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">Email</p>
                <PatientInput name="email" type="email" placeholder="someone@doci.com" width="w-[320px]" height="h-[45px]" register={register} />
                <p className="text-[13px] mt-2 text-red-400">{errors?.email?.message}</p>
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-5  items-center lg:items-start lg:flex-row justify-center md:gap-x-5 xl:gap-x-20">
              <div className="relative">
                <p className="text-[#666666] font-bold text-[14px]">Password</p>
                <PatientInput name="password" type={showPassword ? "text" : "password"} placeholder="*********" width="w-[320px]" height="h-[45px]" register={register} />
                <div className="absolute top-9 right-2 text-[18px]" onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
                <p className="text-[13px] mt-2 text-red-400">{errors?.password?.message}</p>
              </div>

              <div className=" relative">
                <p className="text-[#666666] font-bold text-[14px]">Confirm Password</p>
                <PatientInput name="confirmPassword" type={showConfirm ? "text" : "password"} placeholder="*********" width="w-[320px]" height="h-[45px]" register={register} />
                <div className="absolute top-9 right-2 text-[18px]" onClick={() => setShowConfirm((prev) => !prev)}>
                  {showConfirm ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
                <p className="text-[13px] mt-2 text-red-400">{errors?.confirmPassword?.message}</p>
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-5 items-center lg:items-start lg:flex-row justify-center md:gap-x-5 xl:gap-x-20">
              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">Phone number</p>
                <PatientInput name="phoneNumber" type="number" placeholder="08036462918" width="w-[320px]" height="h-[45px]" register={register} />
                <p className="text-[13px] mt-2 text-red-400">{errors?.phoneNumber?.message}</p>
              </div>

              <div className="">
                <p className="text-[#666666] font-bold text-[14px]">Gender</p>
                <select defaultValue="" required {...register("gender")} className="w-[320px] h-[45px] inputField pl-2 mt-1 pr-3 ">
                  {/* <option>Select Gender</option> */}
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <p className="text-[13px] mt-2 text-red-400">{errors?.gender?.message}</p>
              </div>
            </div>

            <div className="flex w-[320px] gap-x-5 lg:gap-x-4 xl:gap-x-10 ml-0 justify-center items-center">
              <div>
                <p className="text-[#666666] font-bold text-[14px]">Weight (kg)</p>
                <PatientInput name="weight" type="text" placeholder="176" width="w-[90px]" height="h-[45px]" register={register} />
              </div>
              <div>
                <p className="text-[#666666] font-bold text-[14px]">Height (cm)</p>
                <PatientInput name="height" type="text" placeholder="175" width="w-[90px]" height="h-[45px]" register={register} />
              </div>
              <div>
                <p className="text-[#666666] font-bold text-[14px]">Blood type</p>
                <PatientInput name="blood" type="text" placeholder="B+" width="w-[90px]" height="h-[45px]" register={register} />
              </div>
            </div>
          </div>

          <div className="mt-[28px] flex justify-center">
            <PatientAuthButton name="Submit" width="w-[300px]" height="h-[40px]" loading={loading} />
          </div>
        </form>
      </div>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default EmployeePatientForm;
