import React, { useContext, useMemo } from "react";
import { wordShortner } from "../../helpers";
import Logo from "../../assets/images/Logo.jpg";
import moment from "moment";
import { UserContext } from "../../context/userContext";
import { useTable } from "react-table";

import { CSVLink, CSVDownload } from "react-csv";

const OverviewTable = ({ data, columns }) => {
  let user = useContext(UserContext);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows } = useTable({
    columns,
    data,
  });

  const onClick = (el) => {
    user.setShowEmp(true);
    user.setEmpID(el);
  };

  const hd = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Date created", key: "$createdAt" },
  ];

  if (!data) return <div> </div>;

  return (
    <div className="w-full font-commiss">
      <div className="w-full flex justify-end">
        <CSVLink headers={hd} data={data} target="_blank" filename="File.csv" className="w-[100px] h-[40px] centerFlex subscription-gradient border border-red-200 font-semibold hover:opacity-[0.8] hover:scale-[1.05] rounded-lg">
          Export
        </CSVLink>
      </div>
      <div className="flex flex-col w-full overflow-x-scroll horizontal-scrollbar  ">
        <div className="py-2  min-w-[1050px]  ">
          <table {...getTableProps()} className="w-full divide-y divide-gray-200">
            <thead className="bg-[#FFF5F5]  rounded-lg">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} className="bg-white max-w-full divide-y divide-gray-200">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr className="cursor-pointer" onClick={() => onClick(row.original.$id)} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const value = cell.render("Cell").props.value;
                      if (cell.column.id === "name") {
                        return (
                          <td className="px-6 py-4 whitespace-nowrap flex gap-x-3 items-center">
                            <div className="w-[40px] h-[40px] bg-red-500 text-white centerFlex font-semibold rounded-full">
                              {value.split(" ")[0][0]} {value.split(" ")[1][0]}
                            </div>
                            <div>
                              <div className="text-sm text-gray-900">{wordShortner(value.split(" ")[0], 20)}</div>
                              <div className="text-sm text-gray-500">{wordShortner(value.split(" ")[1], 20)}</div>
                            </div>
                          </td>
                        );
                      } else if (cell.column.id === "isVerified") {
                        return (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className={`flex items-center justify-center text-[13px] w-[80px] h-[30px] border-2 rounded-lg ${value ? "border-green-500 bg-green-200 text-green-500" : "border-red-500 bg-red-200 text-red-500"}`}>{value ? "Verified" : "Unverified"}</div>
                          </td>
                        );
                      } else
                        return (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">{cell.render("Cell")}</div>
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OverviewTable;
