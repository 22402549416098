import { createContext, useState } from "react";
import { subscriptionContext } from "../types/contextTypes";

export const SubscriptionContext = createContext<subscriptionContext | null>(
  null
);

export const SubscriptionProvider = ({ children }: any) => {
  const [subPlan, setSubPlan] = useState<boolean>(false);
  const [subDetail, setSubDetail] = useState<boolean>(false);

  const data = {
    subPlan,
    setSubPlan,
    subDetail,
    setSubDetail,
  };

  return (
    <SubscriptionContext.Provider value={data}>
      {children}
    </SubscriptionContext.Provider>
  );
};
