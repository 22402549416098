import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Account } from "appwrite";

import { ReactComponent as Logo } from "../../assets/svgs/Logo.svg";
import { ReactComponent as LoginSvg } from "../../assets/svgs/LoginSvg.svg";
import { ReactComponent as RegisterSvg } from "../../assets/svgs/RegisterSvg.svg";
import LogoMobile from "../../assets/images/Logo.jpg";
import { client } from "../../appwrite/appwriteConfig";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../context/userContext";
import FullPageSpinner from "./FullPageSpinner";

import DoctorImageOne from "../../assets/images/image1.webp";
import DoctorImage from "../../assets/images/image2.webp";

interface LayoutProps {
  children: React.ReactNode;
  type: string;
}


const AuthLayout = ({ children, type }: LayoutProps) => {
  const [user, setUser] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const account = new Account(client);
  const navigate = useNavigate();

  let userObj = useContext(UserContext);

  useEffect(() => {
    if (userObj?.user) {
      setLoading(false);
      navigate("/");
    } else {
      setLoading(false);
      // navigate("/login")
    }
  }, [userObj?.user]);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className={`flex flex-col sm:flex-row min-h-screen font-commiss relative py-4 sm:py-0`}>
      <div className="flex sm:hidden gap-x-5 items-center justify-center sm:justify-start">
        <img src={LogoMobile} alt="" className="w-[100px] h-[100px]" />
        <div className="font-sans font-bold">DOCi Accessible Healthcare</div>
      </div>
      <div className="min-h-full sm:w-[40%] lg:w-[65%] bg-no-repeat bg-center bg-cover flex justify-center" style={{ backgroundImage: `url(${type === "login" ? DoctorImage : DoctorImageOne})` }}>
        <div className=" hidden sm:block w-full sm:w-[40%] lg:w-[65%]">
          <div className="hidden sm:flex gap-x-5 items-center justify-center sm:justify-start">
            <Logo />
            <div className="font-sans font-bold">DOCi Accessible Healthcare</div>
          </div>
        </div>
      </div>

      {children}

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default AuthLayout;



{
  /* <div className="w-full flex justify-center opacity-[0.1] sm:opacity-[1]">
           {type === "login" ? <LoginSvg /> : <RegisterSvg />} 
          <img src='../../assets/images/image1.webp' alt="" />
        </div> */
}

{
  /* <div className="h-full w-full bg-no-repeat bg-center bg-cover flex justify-center opacity-[0.1] sm:opacity-[1] " style={{ backgroundImage: `url(${DoctorImage})` }} /> */
}
