import { Databases, ID, Query } from "appwrite";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../appwrite/appwriteConfig";
import { EmployeeContext } from "../context/employeeContext";
import { UserContext } from "../context/userContext";

type getEmplyoees = {
  orgId: string;
  user: any;
};

const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID || ""; //to deal with env variables not having type definitions
const orgUserColID =
  process.env.REACT_APP_APPWRITE_ORG_USER_COLLECTION_ID || "";
const patientColID = process.env
  .REACT_APP_APPWRITE_PATIENT_COLLECTION_ID as string;
const organizationID = process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID || "";
const subUserID = process.env.REACT_APP_APPWRITE_SUBUSER_ID || "";
const appointmentID = process.env
  .REACT_APP_APPWRITE_APPOINTMENT_COLLECTION_ID as string;
const database = new Databases(client);

export const useHooks = () => {
  let user = useContext(UserContext);
  let employee = useContext(EmployeeContext);

  const GetOrgID = (userId: string) => {
    if (user?.orgID) return;
    else {
      try {
        database
          .listDocuments(dbID, orgUserColID, [Query.equal("userID", userId)])
          .then((res) => {
            user?.setOrgID(res?.documents[0]?.orgID);
            localStorage.setItem("doci_org_id", res?.documents[0]?.orgID);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const useGetOrg = () => {
    if (user?.org) return;
    try {
      database
        .listDocuments(dbID, orgUserColID, [
          Query.equal("userID", user?.user?.userId),
        ])
        .then((res) => {
          database
            .listDocuments(dbID, organizationID, [
              Query.equal("$id", res?.documents[0]?.orgID),
            ])
            .then((res) => {
              user?.setOrg(res?.documents[0]);
            });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllEmployees = () => {
    //||  user?.orgID as string;
    // let id = localStorage.getItem("doci_org_id") as string
    let id = user?.orgID as string;
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.orderDesc("$createdAt"),
        ])
        .then((res) => {
          employee?.setTotalEmployees(res.documents);
        });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const GetAllVerifiedEmployees = () => {
    let id = user?.orgID as string;
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.equal("isVerified", 1),
          Query.orderDesc("$createdAt"),
        ])
        .then((res) => {
          employee?.setAllVerified(res.documents.length);
        });
    } catch (error: any) {
      toast.error("Please refresh the page and check your network");
    }
  };

  const GetVerifiedEmployees = () => {
    let id = user?.orgID as string;
    let page = employee?.greenPage as number;
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.equal("isVerified", 1),
          Query.orderDesc("$createdAt"),
          Query.limit(10),
          Query.offset(0 + (page - 1) * 10),
        ])
        .then((res) => {
          employee?.setVerified(res.documents);
        });
    } catch (error: any) {
      toast.error("Please refresh the page and check your network");
    }
  };

  const GetUnverifiedEmplyoees = () => {
    let id = user?.orgID as string;
    let page = employee?.redPage as number;
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.equal("isVerified", 0),
          Query.orderDesc("$createdAt"),
          Query.limit(10),
          Query.offset(0 + (page - 1) * 10),
        ])
        .then((res) => {
          employee?.setUnverified(res.documents);
        });
    } catch (error: any) {
      toast.error("Please refresh the page and check your network");
    }
  };

  const useGetEmployee = (id: string) => {
    if (employee?.employee) return;
    try {
      database
        .listDocuments(dbID, patientColID, [Query.equal("$id", id)])
        .then((res) => {
          employee?.setEmployee(res.documents);
        });
    } catch (error) {}
  };

  const useGetTime = () => {
    const [time, setTime] = useState<string>("");

    useEffect(() => {
      let current: number = Number(moment().format("HH"));
      if (current >= 3 && current < 12) setTime("Morning");
      else if (current >= 12 && current < 16) setTime("Afternoon");
      else if (current >= 16) setTime("Evening");
    }, []);

    return time;
  };

  const CreateSub = (data: any) => {
    return new Promise((resolve, reject) => {
      database
        .createDocument(dbID, subUserID, ID.unique(), {
          userID: data.userID,
          orgID: data.orgID,
          head_count: data.head_count,
          time: data.time,
          subID: data.subID,
          isPaid: data.isPaid,
        })
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  const changeIsPaid = (id: string, psRef: string) => {
    return new Promise((resolve, reject) => {
      database
        .updateDocument(dbID, subUserID, id, {
          isPaid: 1,
          paystack_ref: psRef,
        })
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  return {
    useGetTime,
    GetOrgID,
    GetAllEmployees,
    useGetEmployee,
    useGetOrg,
    CreateSub,
    changeIsPaid,
    GetVerifiedEmployees,
    GetUnverifiedEmplyoees,
    GetAllVerifiedEmployees,
  };
};

export const useGetAllEmployeeConsultations = () => {
  let user = useContext(UserContext);
  let employee = useContext(EmployeeContext);
  let res1: any;

  const GetAllEmployeeConsultations = async () => {
    try {
      const res = await database
        .listDocuments(dbID, appointmentID, [
          Query.equal("successfulConsultation", "yes"),
          Query.orderDesc("$createdAt"),
        ])
        .then((res) => {
          res1 = res.documents;
          // console.log("res1===>", res1)
        });
    } catch (error: any) {
      toast.error(
        `${error.message} \n Please refresh the page and check your network`
      );
    }

    try {
      let res2: any = await Promise.all(
        res1.map(async (item: any) => {
          let res = await database.getDocument(
            dbID,
            patientColID,
            item.patientID
          );
          console.log("res===>", res);
          return {
            ...res,
            consultation_date: item.date,
          };
        })
      );

      // Filter records base on Month of the consultations
      const todaysDate = moment().format().split("-");
      const newResults = res2.filter(
        (item: any) => item.consultation_date.split("-")[1] === todaysDate[1]
      );

      console.log("res2===>", res2);

      employee?.setEmployeeConsultations(newResults);
    } catch (error: any) {
      toast.error(
        `${error.message} \n Please refresh the page and check your network`
      );
    }
  };

  useEffect(() => {
    GetAllEmployeeConsultations();
  }, []);
};

export const useGetAllVerifiedEmployees = () => {
  let user = useContext(UserContext);
  let employee = useContext(EmployeeContext);
  let id = user?.orgID as string;

  const FetchAllVerfiedEmployees = async () => {
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.equal("isVerified", 1),
          Query.orderDesc("$createdAt"),
        ])
        .then((res) => {
          employee?.setAllVerified(res.documents.length);
        });
    } catch (error: any) {
      toast.error("Please refresh the page and check your network");
    }
  };

  useEffect(() => {
    FetchAllVerfiedEmployees();
  }, [employee?.totalEmployees]);
};

export const useGetAllEmployees = () => {
  let user = useContext(UserContext);
  let employee = useContext(EmployeeContext);

  const FetchEmployees = async () => {
    let id = user?.orgID as string;
    try {
      database
        .listDocuments(dbID, patientColID, [
          Query.equal("orgID", id),
          Query.orderDesc("$createdAt"),
        ])
        .then((res) => {
          employee?.setTotalEmployees(res.documents);
          console.log("res.documents=====>", res.documents);
        });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    FetchEmployees();
  }, [employee?.refresh, user?.orgID]);
};
