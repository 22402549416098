import { Modal } from "@mantine/core";
import { useContext, useEffect, useState } from "react";


import { LoadingOverlay,} from "@mantine/core";

import { SubscriptionContext } from "../../context/subscriptionContext";
import { ReactComponent as Logo } from "../../assets/svgs/Logo4.svg";
import { UserContext } from "../../context/userContext";
import { EmployeeContext } from "../../context/employeeContext";
import { useHooks } from "../../hooks/hooks";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";

export const SubPlanModal = ({ opened, setOpened }) => {
  const [visible, setVisible] = useState(false);
  const [months, setMonths] = useState();
  const [headCount, setHeadCount] = useState();
  const [monthError, setMonthError] = useState("   ");
  const [headCountError, setHeadCountError] = useState("   ");
  //This spacing makes the errors truthy so its can be used in form validation to control if the form can be submitted or not.....see submit form and the inputs' onChange handlers
  const [docRef, setDocRef] = useState("");
  const [paystackRef, setPaystackRef] = useState("");
  const user = useContext(UserContext);
  const emp = useContext(EmployeeContext);

  const { CreateSub, useGetOrg, changeIsPaid } = useHooks();
  // Get org info sent to  UserContext for storage
  useGetOrg();

  const {
    subDetail: { Plan, title, body, amount, $id },
    setSubDetail,
  } = useContext(SubscriptionContext);

  useEffect(() => {
    ///When the payment is successful
    if (paystackRef) {
      setVisible(true);
      changeIsPaid(docRef, paystackRef)
        .then((res) => {
          setVisible(false);
          setOpened(false);
          toast.success("Success");
        })
        .catch((err) => {
          toast.error(err.message);
          setVisible(false);
          setOpened(false);
        });
    }
  }, [paystackRef]);

  const config = {
    reference: `subscription-${new Date().getTime().toString()}`,
    email: user?.user?.providerUid,
    amount: Number(headCount) * Number(months) * Number(amount) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY,
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    //set the paystack reference so that the fxn to update isPaid and paystackRef is fired
    setPaystackRef(reference.reference);
  };

  const onClose = () => {
    console.log("closed");
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (headCountError || monthError) {
      toast.error("Please fill the form correctly");
      return;
    }
    setHeadCountError("");
    setMonthError("");

    setVisible(true);

    CreateSub({
      userID: user?.user?.userId,
      orgID: user?.org?.$id,
      head_count: Number(headCount),
      time: Number(months) * 30,
      subID: $id, ///This is the id of the subscription plan from the subscription collection.
      isPaid: 0,
    })
      .then((res) => {
        setVisible(false);
        // set ref to created document here
        setDocRef(res.$id);
        initializePayment(onSuccess, onClose);
      })
      .catch((err) => {
        toast.error(err.message);
        setVisible(false);
      });
  };

  return (
    <div>
      <LoadingOverlay visible={visible} overlayBlur={2} />
      {
        <Modal
          opened={opened}
          onClose={() => {
            setOpened(false);
            setSubDetail(null);
          }}
          title=""
          size={400}
        >
          <div className="w-full min-h-[550px] font-commiss">
            <div className="w-full  centerFlex">
              <Logo />
            </div>

            <form onSubmit={(e) => submitForm(e)} className="font-commiss">
              <div className="w-full text-center font-semibold break-words">
                {Plan}
              </div>
              <div className="w-full text-center font-semibold break-words">
                {title}
              </div>
              <div className="w-full flex justify-center gap-x-3 font-semibold break-words">
                <div>Amount per user:</div>
                <div>&#8358;{amount}</div>
              </div>
              <div className="w-full text-center  text-[#666666] font-semibold text-[18px] mt-3">
                {user?.org?.company_name}
              </div>

              <div className="flex flex-col px-4 items-center gap-y-4 mt-2">
                <div className="w-[300px] mx-auto">
                  <div className="text-[#666666] text-[14px]">
                    Number of employees
                  </div>
                  <input
                    type="number"
                    name="head_count"
                    placeholder="100"
                    value={headCount}
                    onChange={(e) => {
                      if (e.target.value < 1) {
                        setHeadCountError("Please enter the right number");
                        setHeadCount(e.target.value);
                      } else {
                        setHeadCountError("");
                        setHeadCount(e.target.value);
                      }
                    }}
                    className="w-full h-[40px] inputField pl-2 mt-1 "
                  />
                  <p className="text-[13px] mt-2 text-red-400">
                    {headCountError}
                  </p>
                </div>

                <div className="w-[300px] mx-auto">
                  <div className="text-[#666666] text-[14px]">
                    Number of months
                  </div>
                  <input
                    type="number"
                    name="time"
                    placeholder="1"
                    onChange={(e) => {
                      if (e.target.value < 1) {
                        setMonthError("Please enter the right number");
                        setMonths(e.target.value);
                      } else {
                        setMonthError("");
                        setMonths(e.target.value);
                      }
                    }}
                    value={months}
                    className="w-full h-[40px] inputField pl-2 mt-1 "
                  />
                  <p className="text-[13px] mt-2 text-red-400">{monthError}</p>
                </div>
              </div>

              <div className="w-full flex flex-col items-center justify-center mt-5 px-3 gap-y-4">
                <a
                  href="https://doci.ng/our-plans/"
                  target="_blank"
                  rel="noreferrer"
                  className="w-full"
                >
                  <div className="w-full h-[40px] text-[14px] text-white font-semibold rounded-2xl font-commiss bg-blue-500 centerFlex hover:bg-blue-700">
                    Learn More about this plan
                  </div>
                </a>

                <button
                  type="submit"
                  className="w-full h-[40px] text-[14px] font-commiss text-white font-bold rounded-2xl bg-red-500 centerFlex hover:bg-red-700"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </Modal>
      }
    </div>
  );
};
