import React, { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import AuthLayout from "../components/AuthComponents/AuthLayout";
import { AuthButton, Input } from "../components/AuthComponents/Utilities";
import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  let userObj = useContext(UserContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const account = new Account(client);

  const submitForm = async (data) => {
    setLoading(true);

    try {
      const res = await account.createEmailSession(data.email, data.password);
      localStorage.setItem("doci_org_user", JSON.stringify(res));
      toast("Login successful", { type: "success" });
      setLoading(false);
      // setUser in userContext to the res obj
      userObj.setUser(res);

      // navigate("/");
      window.location.pathname = "/"
    } catch (error) {
      console.log(error.message);
      toast(error.message, { type: "error" });
      setLoading(false);
      return;
    }

    
  };

  return (
    <AuthLayout type="login">
      <div className="w-full h-full my-10 sm:my-auto mx-0 py-8 flex  centerFlex sm:w-[60%] lg:w-[35%]">
        <div className="flex flex-col items-start">
          <div className="font-sans font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0">
            Welcome back to DociHealthcare!{" "}
          </div>
          <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">
            Please sign-in to access your account and get started{" "}
          </div>

          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8">
              <p className="text-[#666666] text-[14px]">Email Address</p>
              <Input
                name="email"
                type="email"
                placeholder="someone@doci.com"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
              />
              <p className="text-[13px] mt-2 text-red-400">
                {errors.email?.message}
              </p>
            </div>

            <div className="mt-8 relative">
              <div className="flex w-full justify-between">
                <p className="text-[#666666] text-[14px]">Password</p>
                <p className="text-[#B21E14] text-[14px] cursor-pointer" onClick={() => navigate('/password-recovery')}>Forgot Password?</p>
              </div>
              <Input
                name="password"
                type={show ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
              />
              <div
                className="absolute top-9 right-2 text-[18px]"
                onClick={() => setShow((prev) => !prev)}
              >
                {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors.password?.message}
              </p>
            </div>

            <div className="mt-4 flex items-center gap-x-4">
              <input type="checkbox" className="inputField" />
              <p className="text-base text-[#6E6B7B]">Remember me</p>
            </div>

            <div className="mt-5">
              <AuthButton
                name="Login"
                width="w-[350px]"
                height="h-[45px]"
                loading={loading}
              />
            </div>
          </form>

          <div className="mt-5 flex w-full text-[#666666] justify-center items-center gap-x-3">
            <p>New on our platform? </p>
            <Link to="/register" className="text-[#B21E14]">
              Create an account
            </Link>
          </div>


        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
