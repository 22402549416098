import { FieldValues, UseFormRegister } from "react-hook-form";
import { Spinner } from "../../assets";

interface InputProps {
  name: string;
  type: string;
  placeholder: string;
  width: string;
  height: string;
  register: UseFormRegister<FieldValues>;
}

interface ButtonProps {
  name: string;
  width: string;
  height: string;
  loading: boolean;
}

export const Input = ({
  type,
  name,
  width,
  height,
  placeholder,
  register,
  ...props
}: InputProps): JSX.Element => {
  return (
    <div>
      <input
        {...register(name)}
        name={name}
        type={type}
        placeholder={placeholder}
        {...props}
        className={`${width} ${height} inputField pl-2 mt-1 `}
      />
    </div>
  );
};

export const PatientInput = ({
  type,
  name,
  width,
  height,
  placeholder,
  register,
  ...props
}: InputProps): JSX.Element => {
  return (
    <div>
      <input
        {...register(name)}
        name={name}
        type={type}
        placeholder={placeholder}
        {...props}
        className={`${width} ${height} inputField pl-2 mt-1 pr-3 `}
      />
    </div>
  );
};

export const AuthButton = ({
  name,
  width,
  height,
  loading,
}: ButtonProps): JSX.Element => {
  return (
    <button
      type="submit"
      disabled={loading}
      className={`${width} ${height} authRed centerFlex gap-x-2 text-white rounded-lg`}
    >
      <div>{name}</div>
      {loading ? <Spinner /> : null}
    </button>
  );
};

export const PatientAuthButton = ({
  name,
  width,
  height,
  loading,
}: ButtonProps): JSX.Element => {
  return (
    <button
      type="submit"
      disabled={loading}
      className={`${width} ${height} authRed centerFlex gap-x-2 text-white rounded-lg`}
    >
      <div>{name}</div>
      {loading ? <Spinner /> : null}
    </button>
  );
};
