import React, { useContext, useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Client, Account, ID, Databases } from "appwrite";

import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";

export const PrivateRoute = () => {
  // const [user, setUser] = useState<any>({});
  const account = new Account(client);
  const navigate = useNavigate()

  let user = useContext(UserContext);


 


  //use a fallback page here to be shown when the getAccount fxn in useEffect is still running 

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export const EmployeesRoute = () => {
  return <Outlet />;
};
