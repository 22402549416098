import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BiCopy } from "react-icons/bi";
import { BsArrowUp } from "react-icons/bs";
import MainLayout from "../components/MainLayout";
import EmployeeTable from "../components/OverviewComponents/EmployeeTable";
import { EmployeeContext } from "../context/employeeContext";
import { UserContext } from "../context/userContext";
import { useHooks } from "../hooks/hooks";

type EmployeeCardProps = {
  type: string;
  number_of_employees: string;
  select: string;
  setSelect: Dispatch<SetStateAction<string>>;
};

type keyValuePair = { [key: string]: any };

const Employees = () => {
  const [select, setSelect] = useState("verified");

  let employees = useContext(EmployeeContext);

  const {GetUnverifiedEmplyoees, GetVerifiedEmployees } = useHooks();
  
  useEffect(() => {
    GetVerifiedEmployees()
     GetUnverifiedEmplyoees()
  }, [employees?.redPage, employees?.greenPage, employees?.refresh])


  let user = useContext(UserContext);


  const data_verified = useMemo(
    () =>
      employees?.verified?.map((el: keyValuePair, index: any) => {
        return {
          $id: el.$id,
          name: `${el.first_name} ${el.last_name}`,
          email: el.email,
          phone_number: el.phone_number,
          dob: el.dob,
          age: el.age,
          gender: el.gender,
          isVerified: el.isVerified,
          serial: index + 1,
        };
      }),
    [employees?.verified]
  ) ;

  const data_unverified = useMemo(
    () =>
      employees?.unverified?.map((el: keyValuePair, index: any) => {
        return {
          $id: el.$id,
          name: `${el.first_name} ${el.last_name}`,
          email: el.email,
          phone_number: el.phone_number,
          dob: el.dob,
          age: el.age,
          gender: el.gender,
          isVerified: el.isVerified,
          serial: index + 1,
        };
      }),
    [employees?.unverified]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "serial",
      },
      { Header: "Employees", accessor: "name" },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Phone No.",
        accessor: "phone_number"
      },
      {
        Header: "Age",
        accessor: "age"
      },
      {
        Header: "Time",
        accessor: "dob"
      },
      {
        Header: "Sex",
        accessor: "gender"
      },
      {
        Header: "Status",
        accessor: "isVerified"
      }
    ],
    []
  );

  return (
    <MainLayout title="Employees">
      <div className="py-6 px-3 md:px-6 font-commiss">
        <div className=" font-bold text-center sm:text-left ">
          Employee Information
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-y-6 justify-center sm:justify-between mt-10">
          {employees?.verified ? (
            <EmployeeCard
              type="verified"
              select={select}
              setSelect={setSelect}
              number_of_employees={employees?.verified.length}
            />
          ) : null}
          {employees?.unverified ? (
            <EmployeeCard
              type="unverified"
              select={select}
              setSelect={setSelect}
              number_of_employees={employees?.unverified.length}
            />
          ) : null}
        </div>

        {/* Employee table */}
        <div className="mt-10">
          {select === "verified" && data_verified ? (
            <EmployeeTable data={data_verified} columns={columns} type="verified"/>
          ) : null}
          {select === "unverified" && data_unverified ? (
            <EmployeeTable data={data_unverified} columns={columns} type="unverified"/>
          ) : null}
        </div>
        {employees?.refresh ? <div></div> : null}
      </div>
    </MainLayout>
  );
};

export default Employees;

const EmployeeCard = ({
  type,
  select,
  number_of_employees,
  setSelect,
}: EmployeeCardProps): JSX.Element => {
  let number = 4;
  return (
    <div
      className={`w-[255px] h-[140px]  flex flex-col justify-between cursor-pointer employee-card px-4 py-6 rounded-lg ${
        select === type ? "bg-[#ffeded]" : "bg-white"
      } `}
      onClick={() => setSelect(type)}
    >
      <div
        className={`self-end min-w-[80px] h-[30px] flex items-center justify-evenly border-2 rounded-xl ${
          type === "verified"
            ? "bg-[#97db67] border-[#346710]"
            : "bg-[#f3a6a6] border-[#fc3e3e]"
        } `}
      ></div>

      <div className="mt-">
        <div className="font-semibold text-[14px] capitalize">
          {type} Employees
        </div>
        <div className="text-[#ACACAC] text-[14px] mt-1">
          Number on this table page: {number_of_employees}
        </div>
      </div>
    </div>
  );
};
