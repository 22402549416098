import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import AuthLayout from "../components/AuthComponents/AuthLayout";
import { AuthButton, Input } from "../components/AuthComponents/Utilities";
import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";



const PasswordRecoveryConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showRetype, setShowRetype] = useState(false);

  const navigate = useNavigate();
  let userObj = useContext(UserContext);
  let location = window.location;

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email().required("Email is required")
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
    retype_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(32, "Password must not exceed 32 characters")
    .required("Please Enter your password").oneOf([Yup.ref('password')], "The passwords do not match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const account = new Account(client);
  

  const queryParams = new URLSearchParams(window.location.search)
  const USER_ID = queryParams.get("userId")
  const SECRET = queryParams.get("secret")



  const submitForm = async (data) => {
    setLoading(true);

    try{
      const res = await account.updateRecovery(USER_ID, SECRET, data.password, data.retype_password)
      console.log(res)
      setLoading(false)
      window.location.href = `${window.location.origin}/login`
    }catch(error) {
      toast(error.message, { type: "error" });
      setLoading(false)
    }

    
  };

  return (
    <AuthLayout type="login">
      <div className="w-full h-full my-10 sm:my-auto mx-0 py-8 flex  centerFlex sm:w-[60%] lg:w-[35%]">
        <div className="flex flex-col items-start">
          <div className="font-sans font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0"> DociHealthcare! </div>
          <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">Update password here</div>

          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8 relative">
              <p className="text-[#666666] text-[15px] font-bold">New password</p>
              <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">Please enter new password here</p>
              <Input
                name="password"
                type={show ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
              />
              <div
                className="absolute top-[70px] right-2 text-[18px]"
                onClick={() => setShow((prev) => !prev)}
              >
                {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors.password?.message}
              </p>
            </div>

            <div className="mt-8 relative">
              <p className="text-[#666666] text-[15px] font-bold">Retype New password</p>
              <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">Please retype new password here</p>
              <Input
                name="retype_password"
                type={showRetype ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
              />
              <div
                className="absolute top-[70px] right-2 text-[18px]"
                onClick={() => setShowRetype((prev) => !prev)}
              >
                {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors.retype_password?.message}
              </p>
            </div>

            <div className="mt-5">
              <AuthButton name="Recover" width="w-[350px]" height="h-[45px]" loading={loading} />
            </div>
          </form>

         
        </div>
      </div>
    </AuthLayout>
  );
};

export default PasswordRecoveryConfirmation;
