import React from "react";
import { ReactComponent as Logo } from "../../assets/svgs/Logo2.svg";

const FullPageSpinner = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className=" flex items-center justify-center animate-bounce">
        <div className=" flex items-center justify-center animate-pulse">
          <Logo />
        </div>
      </div>
    </div>
  );
};

export default FullPageSpinner;
