import React, { useContext, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import AuthLayout from "../components/AuthComponents/AuthLayout";
import { AuthButton, Input } from "../components/AuthComponents/Utilities";
import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";



const PasswordRecovery = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  let userObj = useContext(UserContext);
  let location = window.location;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required")
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const account = new Account(client);

  const submitForm = async (data) => {
    setLoading(true);

    try{
      const res = await account.createRecovery(data?.email, `${location.origin}/password-recovery-confirmation`)
      setLoading(false)
      toast.success("Please check your email for the password recovery link")
    }catch(error) {
      toast(error.message, { type: "error" });
      setLoading(false)
      toast.error(error.message)
    }

    
  };

  return (
    <AuthLayout type="login">
      <div className="w-full h-full my-10 sm:my-auto mx-0 py-8 flex  centerFlex sm:w-[60%] lg:w-[35%]">
        <div className="flex flex-col items-start">
          <div className="font-sans font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0"> DociHealthcare! </div>
          <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">Enter the email for password recovery </div>
          <p className="text-[15px] font-bold text-[#a56f6f] cursor-pointer my-2" onClick={() => navigate('/login')}>Back to login</p>

          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8">
              <p className="text-[#666666] text-[15px] font-bold">Email Address</p>
              <p className="text-[#666666] text-[14px] max-w-[350px] my-1 break-words">Please enter the admin email used to create this company account</p>
              <Input name="email" type="email" placeholder="someone@doci.com" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.email?.message}</p>
            </div>

            <div className="mt-5">
              <AuthButton name="Recover" width="w-[350px]" height="h-[45px]" loading={loading} />
            </div>
          </form>

         
        </div>
      </div>
    </AuthLayout>
  );
};

export default PasswordRecovery;
