import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Client, Account, ID, Databases, Storage  } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthLayout from "../components/AuthComponents/AuthLayout";
import { AuthButton, Input } from "../components/AuthComponents/Utilities";
import { client } from "../appwrite/appwriteConfig";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const RegisterPage = () => {
  const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
  const orgColID = process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID;
  const orgUserColID = process.env.REACT_APP_APPWRITE_ORG_USER_COLLECTION_ID;
  const storageID = process.env.REACT_APP_APPWRITE_BUCKET_ID


  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Please enter the company name"),
    companyAddress: Yup.string().required("Please enter the company address"),
    rcNumber: Yup.string().required("Please enter the company RC number"),
    companyEmail: Yup.string().required("Please enter the company email"),
    firstname: Yup.string().required("Please enter your first name"),
    lastname: Yup.string().required("Please enter your last name"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").max(32, "Password must not exceed 32 characters").required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const database = new Databases(client);

  const account = new Account(client);
  const storage = new Storage(client)
  
  const location = window.location

  

  const submitForm = async (data) => {
    setLoading(true);
    let userId;
    let orgId;

    let orgDetails;


    

    //Create Admin user account
    try {
      const res = await account.create(ID.unique(), data.email, data.password, `${data.firstname} ${data.lastname}`);
      //Set the User id
      userId = res?.$id;
    } catch (error) {
      toast(error.message, { type: "error" });
      setLoading(false);
      return;
    }

    //Create company document in Org collection
    try {
      const res = await database.createDocument(dbID, orgColID, ID.unique(), {
        company_name: data.companyName,
        company_address: data.companyAddress,
        rc_number: data.rcNumber,
        company_email: data.companyEmail,
      });
      //Set the Org id
      orgId = res?.$id;
      orgDetails = res
    } catch (error) {
      toast(error.message, { type: "error" });
      setLoading(false);
      return;
    }


    // Create company link in org collection
    try{
      const res = await database.updateDocument(dbID, orgColID, orgId, {
        company_registration_link: `${location?.origin}/org/${orgDetails?.company_name?.replace(/\s+/g, "-")}-${orgId}`
      })
    }
    catch(error) {
      console.log(error)
    }


    //Create document in OrgUser collection
    if (userId && orgId) {

      try {
        const res = await database.createDocument(dbID, orgUserColID, ID.unique(), {
          userID: userId,
          orgID: orgId,
        });
        setLoading(false);
        toast("Registration successful", { type: "success" });
        // navigate to login page
        navigate("/login");
      } catch (error) {
        console.log(error.message);
        toast(error.message, { type: "error" });
        setLoading(false);
        return;
      }
    }

    


  };

  return (
    <AuthLayout type="register">
      <div className="w-full  h-full sm:my-auto mx-0 py-8 flex  centerFlex sm:w-[60%] lg:w-[35%]">
        <div className="flex flex-col items-start">
          <div className=" font-sans font-semibold text-[18px] text-[#FF0000] mx-auto sm:mx-0">Welcome to DociHealthcare! </div>
          <div className="text-[#666666] text-[14px] mt-3 mx-auto sm:mx-0">Please sign-up to access your account and get started</div>

          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8">
              <p className="text-[#666666] text-[14px]">Company Name</p>
              <Input name="companyName" type="text" placeholder="Company" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.companyName?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">Company Address</p>
              <Input name="companyAddress" type="text" placeholder="No. 1 Company Street" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.companyAddress?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">Company RC Number</p>
              <Input name="rcNumber" type="text" placeholder="RC0396562929" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.rcNumber?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">Company Email</p>
              <Input name="companyEmail" type="email" placeholder="company@doci.com" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.companyEmail?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">First Name</p>
              <Input name="firstname" type="text" placeholder="someone" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.firstname?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">Last Name</p>
              <Input name="lastname" type="text" placeholder="someone" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.lastname?.message}</p>
            </div>

            <div className="mt-5">
              <p className="text-[#666666] text-[14px]">Email Address</p>
              <Input name="email" type="email" placeholder="someone@doci.com" width="w-[350px]" height="h-[45px]" register={register} />
              <p className="text-[13px] mt-2 text-red-400">{errors.email?.message}</p>
            </div>

            <div className="mt-5 relative">
              <div className="flex w-full justify-between">
                <p className="text-[#666666] text-[14px]">Password</p>
                <p className="text-[#B21E14] text-[14px]">Forgot Password?</p>
              </div>
              <Input name="password" type={show ? "text" : "password"} placeholder="***********" width="w-[350px]" height="h-[45px]" register={register} />
              <div className="absolute top-9 right-2 text-[18px]" onClick={() => setShow((prev) => !prev)}>
                {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">{errors.password?.message}</p>
            </div>

            <div className="mt-4 flex items-center gap-x-4">
              <input type="checkbox" className="inputField" />
              <p className="text-base text-[#6E6B7B]">Remember me</p>
            </div>

            {/* <div className="mt-6 w-[200px] gap-x-4">
              <div className="text-[14px] text-[#666666] font-bold">Company Logo</div>
              <label htmlFor="file" className="cursor-pointer">
                <input type="file" id="file" accept=".jpg, .png, .jpeg" onChange={(e) =>{setFile(e.target.files[0]); console.log(e.target.files[0])} } className="hidden" />
                <div className="w-[100px] h-[40px] text-[14px] flex justify-center items-center border border-1 my-1 rounded-md text-[#666666]">Select Image</div>
              </label>
            </div> */}

            <div className="mt-5">
              <AuthButton name="Create account" width="w-[350px]" height="h-[45px]" loading={loading} />
            </div>
          </form>

          <div className="mt-5 flex w-full text-[#666666] justify-center items-center gap-x-3">
            <p>Have an account? </p>
            <Link to="/login" className="text-[#B21E14]">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default RegisterPage;
