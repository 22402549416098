import { createContext, Dispatch, SetStateAction, useState } from "react";
import { getUser, getUserInfo } from "../helpers";
import { userContextTypes } from "../types/contextTypes";

export const UserContext = createContext<userContextTypes | null>(null);
// const currentUser = getUser()

const currentUser = getUser();

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(currentUser);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [subPlan, setSubPlan] = useState<boolean>(false);
  const [showEmp, setShowEmp] = useState<boolean>(false);
  const [orgID, setOrgID] = useState<string>(localStorage.getItem("doci_org_id") || "");
  const [test, setTest] = useState<string>("");
  const [empID, setEmpID] = useState<string>("");
  const [org, setOrg] = useState<any>(null);


  const data = {
    user,
    setUser,
    userInfo,
    setUserInfo,
    subPlan,
    setSubPlan,
    orgID,
    setOrgID,
    test,
    setTest,
    showEmp,
    setShowEmp,
    empID,
    setEmpID,
    org,
    setOrg
  };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
