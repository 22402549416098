import { Account } from "appwrite";
import { client } from "./appwrite/appwriteConfig";

const account = new Account(client);

export const wordShortner = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    let word = text.slice(0, maxLength);
    word = word.concat("...");
    return word;
  } else {
    return text;
  }
};

export const getUser = () => {
  if (localStorage.getItem("doci_org_user")) {
    const user = localStorage.getItem("doci_org_user");
    if (user) return JSON.parse(user);
    else {
      
      return null;
    }
  } else return null;
};

export const getUserInfo = () => {
  account
    .get()
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => console.log(err.message));
};
