import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { BiLogOutCircle, BiSearchAlt } from "react-icons/bi";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Client, Account, ID, Databases } from "appwrite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "@mantine/core";
// import {Helmet} from "react-helmet";

import { ReactComponent as Logo } from "../assets/svgs/Logo.svg";
import { ReactComponent as Alert } from "../assets/svgs/Alert.svg";
import HeaderImage from "../assets/images/header.jpg";
import {
  Overview,
  Employees,
  Subscription,
  Settings,
} from "../assets/index.js";
import { AiOutlineMenu } from "react-icons/ai";
import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";
import FullPageSpinner from "./AuthComponents/FullPageSpinner";
import { SubModalType } from "../types/subscriptionTypes";
import { SubPlanModal } from "./Modals/SubPlanModal";
import { useHooks } from "../hooks/hooks";
import { EmpModal } from "./Modals/EmpModal";
import { SubscriptionContext } from "../context/subscriptionContext";

interface MainLayoutProps {
  children: React.ReactNode;
  title: string;
}

interface DataItems {
  id: number;
  href: string;
  name: string;
  icon: (a: string) => React.ReactNode;
}

interface Data {
  data: DataItems;
  select: string;
  routeType: string;
  setSelect: Dispatch<SetStateAction<string>>;
  setRouteType: Dispatch<SetStateAction<string>>;
}

interface ControlProps {
  control: boolean;
  setControl: Dispatch<SetStateAction<boolean>>;
  slideIn?: boolean;
  setSlideIn: Dispatch<SetStateAction<boolean>>;
  username?: string;
}

const SidebarData: DataItems[] = [
  {
    id: 1,
    href: "/",
    name: "overview",
    icon: (type: string) => <Overview type={type} />,
  },
  {
    id: 2,
    href: "/employees",
    name: "employees",
    icon: (type: string) => <Employees type={type} />,
  },
  {
    id: 3,
    href: "/subscription",
    name: "subscription",
    icon: (type: string) => <Subscription type={type} />,
  },
  {
    id: 4,
    href: "/settings",
    name: "settings",
    icon: (type: string) => <Settings type={type} />,
  },
];

const MainLayout = ({ children, title }: MainLayoutProps) => {
  const [control, setControl] = useState<boolean>(false);
  const [slideIn, setSlideIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const account = new Account(client);

  let userObj = useContext(UserContext);
  let subObj = useContext(SubscriptionContext);

  const { GetOrgID } = useHooks();



  useEffect(() => {
    try {
      if (userObj?.userInfo?.name) return;
      account.get().then((res) => userObj?.setUserInfo(res));
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (userObj?.user) {
      setLoading(false);
      GetOrgID(userObj?.user?.userId);
    } else {
      setLoading(false);
      navigate("/login");
    }
  }, [userObj?.user]);


  console.log("userObj?.userInfo==>", userObj?.userInfo)
  console.log("userObj?.user====>", userObj?.user)
  

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="relative font-commiss">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet> */}
      <Header
        control={control}
        setControl={setControl}
        setSlideIn={setSlideIn}
        username={userObj?.userInfo?.name}
      />
      <div className="min-h-screen w-full lg:translate-x-[250px] lg:w-[calc(100%-250px)] ">
        {children}
      </div>
      <Sidebar />
      <MobileSidebar
        control={control}
        setControl={setControl}
        setSlideIn={setSlideIn}
        slideIn={slideIn}
      />

      {/* mantine modals lie here */}
      {subObj?.subPlan ? (
        <SubPlanModal opened={subObj?.subPlan} setOpened={subObj?.setSubPlan} />
      ) : null}

      {userObj?.showEmp ? (
        <EmpModal opened={userObj?.showEmp} setOpened={userObj?.setShowEmp} />
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default MainLayout;

// Header Component
const Header = ({
  control,
  setControl,
  setSlideIn,
  username,
}: ControlProps): JSX.Element => {
  const [drop, setDrop] = useState<boolean>(false);
  const account = new Account(client);

  const user = useContext(UserContext);
  const { useGetOrg } = useHooks();

  useGetOrg();

  const logOut = () => {
    account.deleteSessions().then(
      (res) => {
        setDrop(false);
        localStorage.removeItem("doci_org_user");
        localStorage.removeItem("doci_org_id");
        window.location.pathname = "/login";
      },
      (err) => {
        toast(err.message, { type: "error" });
        console.log(err);
        setDrop(false);
      }
    );
  };

  console.log("username===>", username)

  return (
    <div className="sticky top-0 w-full h-[100px] font-commiss backGround flex items-center justify-between gap-x-4 sm:px-4 lg:px-10 z-20">
      <div className="flex gap-x-5 items-center font-commiss">
        <Logo />
        <div className=" font-bold">DOCi Accessible Healthcare</div>
      </div>

      <div className="text-[24px] hidden md:block font-commiss text-gray-600 font-semibold">
        {user?.org?.company_name}
      </div>

      <div className="flex items-center gap-x-6 lg:gap-x-10">
        <div className="relative hidden lg:block">
          <Alert />
          <div className="w-[8px] h-[8px] rounded-full bg-[#FF0000] absolute top-0 right-0" />
        </div>

        <div
          className="w-[50px] h-[50px] bg-gradient-to-br rounded-full from-[#FF9A9A] to-[#0E9CC4] flex text-gray-100 font-semibold text-[18px] centerFlex"
          onClick={() => setDrop((prev) => !prev)}
        >
        {username?.split(" ")?.[0]?.[0]} {username?.split(" ")?.[1]?.[0]}
        </div>

        {!control ? (
          <div
            className="block lg:hidden cursor-pointer"
            onClick={() => {
              setControl((prev) => !prev);
              setSlideIn(true);
            }}
          >
            <AiOutlineMenu color="black" size={24} />
          </div>
        ) : null}

        {drop ? (
          <div className="w-[200px] py-6 absolute right-10 top-[100px] rounded-md backGround bg-white z-10 flex flex-col gap-y-3">
            <div className="ml-6">{username}</div>
            <div
              className="flex gap-x-3 items-center px-6 hover:bg-white py-2 text-lightDark cursor-pointer"
              onClick={() => {
                // Log user out
                logOut();
              }}
            >
              <BiLogOutCircle />
              <div>Logout</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

// Desktop Sidebar Component
const Sidebar: React.FC = () => {
  const [select, setSelect] = useState<string>("overview");
  const [routeType, setRouteType] = useState<string>("/");

  return (
    <div className="hidden lg:block fixed h-screen pt-[100px] w-[250px] top-0 backGround z-10  font-commiss">
      {/* <div className='max-h-full overflow-scroll styled-scrollbar'> */}
      <div className="">
        <div className="w-[190px] mt-10  flex flex-col gap-y-5">
          {SidebarData.map((el, index) => (
            <SidebarSection
              key={index}
              data={el}
              select={select}
              setSelect={setSelect}
              routeType={routeType}
              setRouteType={setRouteType}
            />
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

// Mobile Sidebar Component
const MobileSidebar = ({
  control,
  setControl,
  slideIn,
  setSlideIn,
}: ControlProps): JSX.Element => {
  const [select, setSelect] = useState<string>("overview");
  const [routeType, setRouteType] = useState<string>("/");

  return (
    <div
      className={`${
        slideIn ? "sidebar" : "no-sidebar"
      }  fixed block lg:hidden h-screen pt-[50px] w-[250px] top-0 font-commiss backGround z-30 rounded-r-3xl`}
    >
      {/* <div className='max-h-full overflow-scroll styled-scrollbar'> */}
      <div
        className="text-2xl font-900 text-[#999999] absolute right-10 cursor-pointer "
        onClick={() => {
          setControl((prev) => !prev);
          setSlideIn(false);
        }}
      >
        X
      </div>
      <div className="">
        <div className="w-[190px] mt-20  flex flex-col gap-y-5">
          {SidebarData.map((el, index) => (
            <SidebarSection
              key={index}
              data={el}
              select={select}
              setSelect={setSelect}
              routeType={routeType}
              setRouteType={setRouteType}
            />
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

//  Sidebar Navigation Elements
const SidebarSection = ({
  data,
  select,
  routeType,
  setSelect,
  setRouteType,
}: Data) => {
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const path = location.pathname.slice(0, 4);

    if (location.pathname === "/") {
      setRouteType("overview");
    } else if (path === "/emp") {
      setRouteType("employees");
    } else if (path === "/sub") {
      setRouteType("subscription");
    } else if (path === "/set") {
      setRouteType("settings");
    }
  }, [location.pathname]);


  return (
    <Link to={data.href}>
      <div
        className={`w-full flex items-center py-4 justify-start gap-x-5 pr-3 font-commiss rounded-r-xl ${
          routeType === data.name ? "bg-[#FFF5F5]" : "bg-transparent"
        }`}
      >
        <div className="ml-10 min-w-[30px] centerFlex ">
          {data.icon(routeType)}
        </div>
        {
          <div
            className={`capitalize ${
              routeType === data.name ? "text-[#F44336]" : "text-[#999999]"
            }`}
          >
            {data.name}
          </div>
        }
      </div>
    </Link>
  );
};
