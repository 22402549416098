import { useContext, useState } from "react";

import { Routes, Route } from "react-router-dom";
import { Client, Account, ID, Databases } from "appwrite";

import LoginPage from "./pages/LoginPage.js";
import RegisterPage from "./pages/RegisterPage.js";
import { EmployeesRoute, PrivateRoute } from "./components/ParentRoutes";
import Overview from "./pages/Overview";
import Employees from "./pages/Employees";
import Settings from "./pages/Settings";
import Subscription from "./pages/Subscription";
import SingleEmployee from "./pages/SingleEmployee";

import { client } from "./appwrite/appwriteConfig.js";
import { UserContext } from "./context/userContext";
import FullPageSpinner from "./components/AuthComponents/FullPageSpinner";
import EmployeePatientForm from "./components/AuthComponents/EmployeePatientForm.jsx";
import IndividualPatientForm from "./components/AuthComponents/IndividualPatientForm.jsx";
import PasswordRecovery from "./pages/PasswordRecovery.js";
import PasswordRecoveryConfirmation from "./pages/PasswordRecoveryConfirmation.js";

function App() {
  const user = useContext(UserContext);

  return (
    <div>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/password-recovery' element={<PasswordRecovery />} />
        <Route
          path='/password-recovery-confirmation'
          element={<PasswordRecoveryConfirmation />}
        />

        {/* <Route path="/" element={<PrivateRoute />}> */}
        <Route path='/' element={<Overview />} />
        <Route path='/employees' element={<EmployeesRoute />}>
          <Route path='' element={<Employees />} />
          <Route path=':id' element={<SingleEmployee />} />
        </Route>
        <Route path='/subscription' element={<Subscription />} />
        <Route path='/settings' element={<Settings />} />
        {/* <Route path="/sign-up-patient/:id/new" element={<EmployeePatientForm />} /> */}
        <Route path='/org/:id' element={<EmployeePatientForm />} />
        <Route
          path='/sign-up-patient/individual/new'
          element={<IndividualPatientForm />}
        />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;
