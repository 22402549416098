import { createContext, useState } from "react";
import { employeeContext } from "../types/contextTypes";

export const EmployeeContext = createContext<employeeContext | null>(null);

export const EmployeeProvider = ({ children }: any) => {
  const [verified, setVerified] = useState<any>();
  const [unverified, setUnverified] = useState<any>();
  const [employee, setEmployee] = useState<any>();
  const [totalEmployees, setTotalEmployees] = useState<any>();
  const [allVerified, setAllVerified] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [redPage, setRedPage] = useState<number>(1);
  const [greenPage, setGreenPage] = useState<number>(1);
  const [employeeConsultations, setEmployeeConsultations] = useState<any>();

  const data = {
    totalEmployees,
    setTotalEmployees,
    employee,
    setEmployee,
    verified,
    setVerified,
    unverified,
    setUnverified,
    refresh,
    setRefresh,
    redPage,
    setRedPage,
    greenPage,
    setGreenPage,
    allVerified,
    setAllVerified,
    employeeConsultations,
    setEmployeeConsultations,
  };

  return (
    <EmployeeContext.Provider value={data}>{children}</EmployeeContext.Provider>
  );
};

//redPage is the current page number for the unverified employees table
//greenPage is the current page number for the verified employees table
