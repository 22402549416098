import React, { useContext, useEffect, useState } from "react";
import { BsArrowRight, BsCheck2 } from "react-icons/bs";
import { Modal, Button, Group } from "@mantine/core";

import MainLayout from "../components/MainLayout";
import { ReactComponent as Logo } from "../assets/svgs/Logo3.svg";
import {
  SubscriptionCardProps,
  SubscriptionCardType,
} from "../types/subscriptionTypes";
import { Databases, Query } from "appwrite";
import { client } from "../appwrite/appwriteConfig";
import { UserContext } from "../context/userContext";
import { SubscriptionContext } from "../context/subscriptionContext";

const Subscription = () => {
  const [select, setSelect] = useState<string>("");
  const [dociPlan, setDociPlan] = useState<Array<SubscriptionCardProps>>();

  const subUser = useContext(SubscriptionContext);

  const database = new Databases(client);
  const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID || ""; //to deal with env variables not having type definitions
  const subColID =
    process.env.REACT_APP_APPWRITE_SUBSCRIPTION_COLLECTION_ID || "";

  useEffect(() => {
    const getSubscriptions = async () => {
      const res = await database.listDocuments(dbID, subColID);
      //this ts-ignore is for bypassing the document[] error caused in the setDociPlan function
      //@ts-ignore
      setDociPlan(res.documents);
    };
    getSubscriptions();
  }, []);


  return (
    <MainLayout title="Subscription">
      <div className="py-6 px-3 md:px-10 xl:px-16 min-h-screen bg-gray-100 font-commiss">
        <div className="flex flex-col items-center ">
          <div className="text-[40px] font-bold text-gradient">
            Flexible Plans
          </div>
          <div className="w-[300px] text-center text-gray-500 ">
            Choose a plan that works best for you and your team
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center xl:justify-items-start gap-y-10 gap-x-16 mt-10">
            {/* subscription-cards */}
            {dociPlan?.map((el, index) => (
              <div
                key={index}
                className={`w-[250px] h-[280px] relative rounded-xl bg-white px-4 py-4 cursor-pointer subShadow ${
                  select === el.Plan
                    ? "hover:scale-1 scale-110 bg-[#4d0000] z-[2] border-4 border-white"
                    : "subHover z-[1]"
                }`}
                onClick={() => setSelect(el.Plan)}
              >
                <div className="flex gap-x-4 items-center">
                  <Logo />
                  <div className="flex flex-col text-[14px] font-semibold">
                    <p
                      className={`${
                        select === el?.Plan ? "text-white" : "text-black"
                      }`}
                    >
                      {el.Plan}
                    </p>
                    <div className="flex h-[26px]">
                      <div className="text-[14px] text-gray-400">&#8358;</div>
                      <div
                        className={`${
                          select === el?.Plan ? "text-white" : "text-black"
                        }`}
                      >
                        {el?.amount.toLocaleString()}
                      </div>
                      <div className="text-[14px] text-gray-400 mt-[5px]">
                        / user
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 border-b-[1px] border-gray-400" />

                <div className="mt-4 flex flex-col gap-y-2">
                  <div className="flex justify-center w-full gap-x-2 font-semibold ">
                    <div className="w-full text-center break-words text-[14px]  text-gray-400">
                      {el.title}
                    </div>
                  </div>
                  <div className="flex justify-center w-full gap-x-2 font-semibold ">
                    <div className="w-[90%] text-center break-words text-[14px]  text-gray-400">
                      {el.description}
                    </div>
                  </div>
                </div>

                <div
                  className="w-full h-[45px] text-white rounded-lg bg-[#ff413b] text-[14px] mt-4 flex justify-center items-center gap-x-3 hover:opacity-60"
                  onClick={() => {
                    subUser?.setSubPlan(true);
                    //set subDetails
                    subUser?.setSubDetail(el)
                  }}
                >
                  <div>Choose Plan</div>
                  <BsArrowRight />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Subscription;
