import React from 'react'
import MainLayout from '../components/MainLayout'

const SingleEmployee = () => {
  return (
    <MainLayout title="Employee">
        <div>SingleEmployee</div>
    </MainLayout>
  )
}

export default SingleEmployee