import React, { useContext, useState } from "react";
import MainLayout from "../components/MainLayout";

import { Client, Account, ID, Databases, Query, Storage } from "appwrite";
import { client } from "../appwrite/appwriteConfig";
import { Spinner } from "../assets";
import { UserContext } from "../context/userContext";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldValues, UseFormRegister } from "react-hook-form";
import { AuthButton } from "../components/AuthComponents/Utilities";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type FormValues = {
  email?: string;
  password?: string;
};

const Settings = () => {
  const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID as string;
  const orgColID = process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID as string;
  const orgUserColID = process.env
    .REACT_APP_APPWRITE_ORG_USER_COLLECTION_ID as string;
  const storageID = process.env.REACT_APP_APPWRITE_BUCKET_ID as string;
  const storage = new Storage(client);

  let user = useContext(UserContext);

  const database = new Databases(client);
  const account = new Account(client);

  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOne, setLoadingOne] = useState<boolean>(false);
  const [loadingTwo, setLoadingTwo] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(user?.user?.providerUid);

  console.log(user?.user?.providerUid);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const validationSchema2 = Yup.object().shape({
    old_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters")
      .required("Please Enter your password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const formOptions2 = { resolver: yupResolver(validationSchema2) };
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: formState2,
    reset
  } = useForm(formOptions2);
  const { errors: errors2 } = formState2;


  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else {
      setFile(e?.target?.files[0]);
    }
  };

  const upLoadToAppwrite = async () => {
    let imageID;
    if (file) {
      setLoading(true);
      try {
        const res = await storage.createFile(storageID, ID.unique(), file);
        imageID = res.$id;
      } catch (error) {
        console.log(error);
      }

      try {
        const res = await database.updateDocument(
          dbID,
          orgColID,
          user?.orgID as string,
          {
            image_id: imageID,
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const submitForm = async (data: any) => {
    setLoadingOne(true);
    try {
      const res = await account.updateEmail(data.email, data.password);
      const res2 = await account.createEmailSession(data.email, data.password);
      localStorage.setItem("doci_org_user", JSON.stringify(res2));
      setLoadingOne(false);
      toast.success("Successfully updated email");
      setEmail(res.email)
    } catch (error: any) {
      setLoadingOne(false);
      toast.error(error.message);
    }
  };

  const submitForm2 = async (data: any) => {
    console.log("data===>", data);
    setLoadingTwo(true);
    try {
      const res = await account.updatePassword(data.new_password, data.old_password);
      setLoadingTwo(false);
      toast.success("Successfully updated password");
      console.log(res);
    } catch (error: any) {
      setLoadingTwo(false);
      toast.error(error.message);
      console.log(error);
    }
    reset()
  };

  return (
    <MainLayout title="Settings">
      <div className="py-6 px-3 md:px-6 font-commiss">
        {/* <div>Set Company logo</div> */}

        <div className="mt-6 w-[200px] gap-x-4">
          <div className="text-[14px] text-[#666666] font-bold">
            Set Company Logo
          </div>
          <label htmlFor="file" className="cursor-pointer">
            <input
              type="file"
              id="file"
              accept=".jpg, .png, .jpeg"
              onChange={handleFileUpload}
              className="hidden"
            />
            <div className="w-[100px] h-[40px] text-[14px] flex justify-center items-center border border-1 my-1 rounded-md text-[#666666]">
              Select Image
            </div>
          </label>
        </div>

        <button
          className="w-[300px] h-[50px] w-[100px] h-[40px] text-[14px] flex justify-center font-semibold items-center border border-1 my-1 rounded-md text-[#666666] bg-slate-300"
          disabled={loading}
          onClick={upLoadToAppwrite}
        >
          {loading ? <Spinner /> : "Upload"}
        </button>

        {/* Change email and password */}
        <div className="mt-16 flex flex-col md:flex-row gap-y-10 gap-x-6 lg:gap-x-20">
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mt-8">
              <p className="text-[#666666] text-[14px]">Email Address</p>
              <Input
                name="email"
                type="email"
                placeholder="someone@doci.com"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
                defaultValue={email}
              />
              {
                <p className="text-[13px] mt-2 text-red-400">
                  {errors?.email?.message?.toString()}
                </p>
              }
            </div>

            <div className="mt-8 w-[350px] relative">
              <div className="flex w-full justify-between">
                <p className="text-[#666666] text-[14px]">Password</p>
              </div>
              <Input
                name="password"
                type={show ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register}
              />
              <div
                className="absolute top-9 right-2 text-[18px]"
                onClick={() => setShow((prev) => !prev)}
              >
                {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors?.password?.message?.toString()}
              </p>
            </div>

            <div className="mt-5">
              <AuthButton
                name="Update Email"
                width="w-[350px]"
                height="h-[45px]"
                loading={loadingOne}
              />
            </div>
          </form>

          <form onSubmit={handleSubmit2(submitForm2)}>
          <div className="mt-8 w-[350px] relative">
              <div className="flex w-full justify-between">
                <p className="text-[#666666] text-[14px]">Old Password</p>
              </div>
              <Input
                name="old_password"
                type={show3 ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register2}
              />
              <div
                className="absolute top-9 right-2 text-[18px]"
                onClick={() => setShow3((prev) => !prev)}
              >
                {show2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors2?.old_password?.message?.toString()}
              </p>
            </div>

            <div className="mt-8 w-[350px] relative">
              <div className="flex w-full justify-between">
                <p className="text-[#666666] text-[14px]">New Password</p>
              </div>
              <Input
                name="new_password"
                type={show2 ? "text" : "password"}
                placeholder="***********"
                width="w-[350px]"
                height="h-[45px]"
                register={register2}
              />
              <div
                className="absolute top-9 right-2 text-[18px]"
                onClick={() => setShow2((prev) => !prev)}
              >
                {show2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
              <p className="text-[13px] mt-2 text-red-400">
                {errors2?.new_password?.message?.toString()}
              </p>
            </div>

            <div className="mt-5">
              <AuthButton
                name="Update password"
                width="w-[350px]"
                height="h-[45px]"
                loading={loadingTwo}
              />
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default Settings;

interface InputProps {
  name: string;
  type: string;
  placeholder: string;
  width: string;
  height: string;
  defaultValue?: string;
  register: UseFormRegister<FieldValues>;
}

const Input = ({
  type,
  name,
  width,
  height,
  placeholder,
  register,
  defaultValue,
  ...props
}: InputProps): JSX.Element => {
  return (
    <div>
      <input
        {...register(name)}
        name={name}
        type={type}
        defaultValue={type === "email" ? defaultValue : ""}
        placeholder={placeholder}
        {...props}
        className={`${width} ${height} inputField pl-2 mt-1 `}
      />
    </div>
  );
};
