import { Modal } from "@mantine/core";
import { Databases } from "appwrite";
import { useContext, useState } from "react";
import { BsPersonFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { client } from "../../appwrite/appwriteConfig";
// import Avatar from '../../assets/images/avatar.jpg'

import { EmployeeContext } from "../../context/employeeContext";
import { UserContext } from "../../context/userContext";
import { useHooks } from "../../hooks/hooks";
import { Spinner } from "../../utilities";

export const EmpModal = ({ opened, setOpened }) => {
  const [loading, setLoading] = useState(false);
  const [emp, setEmp] = useState();
  const { useGetEmployee } = useHooks();

  const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID || "";
  const patientColID =
    process.env.REACT_APP_APPWRITE_PATIENT_COLLECTION_ID || "";

  const database = new Databases(client);

  let user = useContext(UserContext);
  useGetEmployee(user?.empID);

  let employee = useContext(EmployeeContext);

  const location = useLocation();

  const handleClick = async (status) => {
    setLoading(true);
    try {
      await database
        .updateDocument(dbID, patientColID, employee.employee[0].$id, {
          isVerified: status === 1 ? 0 : 1,
        })
        .then((res) => {
          toast.success("Update successful");
          //This ensuing if else statement controls the table refreshing to render the changes to the document.
          if (location.pathname === "/") {
            employee?.setTotalEmployees(null);
            employee?.setEmployee(null);
            employee.setRefresh((prev) => !prev);
          } else if (location.pathname === "/employees") {
            // employee.setVerified(null);
            // employee.setUnverified(null);
            employee.setRefresh((prev) => !prev);
            employee?.setEmployee(null);
          }
          setLoading(false);
          user?.setEmpID(null);
          user?.setShowEmp(false);
        });
    } catch (error) {
      console.log(error);
      toast(error.message, { type: "error" });
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
        employee.setEmployee(null);
        user?.setEmpID(null);
      }}
      title=''>
      {/* Modal content linear-gradient(to top, rgb(156, 163, 175), rgb(243, 244, 246), rgb(255, 255, 255)) */}
      {Boolean(employee?.employee) === false ? (
        <div className='w-full centerFlex '>
          <Spinner />
        </div>
      ) : (
        <div className='w-400px min-h-[500px] flex flex-col justify-start  font-commiss'>
          <div className='text-center font-semibold text-[18px]'>
            Employee status update
          </div>
          <div
            className={`w-[230px] h-[230px] mx-auto mt-4 py-6 ${
              employee.employee?.[0]?.isVerified ? "bg-green-300" : "bg-red-300"
            } centerFlex rounded-full`}>
            <BsPersonFill size={150} color='white' />
          </div>
          <div className='w-full text-center text-[18px] font-semibold mt-2'>
            {employee.employee?.[0]?.first_name}{" "}
            {employee.employee?.[0]?.last_name}
          </div>
          <div className='w-full text-center text-gray-500 font-semibold mt-1'>
            {employee.employee?.[0]?.dob}
          </div>
          <div className='w-full text-center text-gray-500 font-semibold mt-1'>
            {employee.employee?.[0]?.email}
          </div>
          <div className='w-full text-center text-gray-500 font-semibold mt-1'>
            {employee.employee?.[0]?.phone_number}
          </div>
          <div className='w-full centerFlex mt-2'>
            <div
              className={`px-4 h-[50px] rounded-lg centerFlex gap-x-3 cursor-pointer border bg-transparent border-blue-400 hover:border-0 ${
                !employee.employee?.[0]?.isVerified
                  ? "hover:bg-green-300"
                  : "hover:bg-red-300"
              }`}
              onClick={() => handleClick(employee.employee?.[0]?.isVerified)}>
              {!loading ? (
                <div>
                  Click to{" "}
                  {Boolean(employee.employee?.[0]?.isVerified) === false
                    ? "Verify"
                    : "Unverify"}
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
