import { useContext, useMemo, useState } from "react";

import { Databases } from "appwrite";

import moment from "moment";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import { client } from "../appwrite/appwriteConfig";
import MainLayout from "../components/MainLayout";
import OverviewTable from "../components/OverviewComponents/OverviewTable";
import { EmployeeContext } from "../context/employeeContext";
import { UserContext } from "../context/userContext";
import {
  useGetAllEmployeeConsultations,
  useGetAllEmployees,
  useGetAllVerifiedEmployees,
  useHooks,
} from "../hooks/hooks";
import { OverviewData } from "../types/overviewTypes";
// import { useGetEmployees, useGetOrgID, useGetTime} from "../hooks/hooks";

type keyValuePair = { [key: string]: any };

const dbID = process.env.REACT_APP_APPWRITE_DATABASE_ID as string;
const orgColID = process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID as string;

const Overview = () => {
  let location = window.location;
  let orgURL = "";
  if (process.env.NODE_ENV === "development") {
    orgURL = location.origin;
  } else {
    orgURL = process.env.REACT_APP_ORG_URL as string;
  }

  const overviewData: Array<OverviewData> = [
    {
      id: 1,
      title: "All Employees",
      info: "Total number of employees",
      number: 31,
    },

    {
      id: 2,
      title: "Consultations",
      info: "For the month of ",
      number: 140,
    },

    {
      id: 3,
      title: "Active Subscriptions",
      info: "All verified employees",
      number: 140,
    },
  ];

  const { GetAllVerifiedEmployees, useGetTime } = useHooks();

  let user = useContext(UserContext);
  let employee = useContext(EmployeeContext);

  const [loading, setLoading] = useState(false);

  const database = new Databases(client);

  // useEffect(() => {
  //   GetAllEmployees();
  // }, [employee?.refresh, user?.orgID]);

  /**
   * useEffect(() => {
    GetAllVerifiedEmployees();
  }, [employee?.totalEmployees]);
   */

  console.log(moment().format("LL").split(" ")[0]);

  useGetAllEmployees();

  useGetAllVerifiedEmployees();

  useGetAllEmployeeConsultations();

  // Get the time of day
  let time = useGetTime();

  const data_all = useMemo(
    () =>
      employee?.totalEmployees
        ?.slice(0, 10)
        ?.map((el: keyValuePair, index: any) => {
          return {
            $id: el.$id,
            name: `${el.first_name} ${el.last_name}`,
            email: el.email,
            phone_number: el.phone_number,
            dob: el.dob,
            age: el.age,
            gender: el.gender,
            isVerified: el.isVerified,
            serial: index + 1,
          };
        }),
    [employee?.totalEmployees]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "serial",
      },
      { Header: "Employees", accessor: "name" },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone No.",
        accessor: "phone_number",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Time",
        accessor: "dob",
      },
      {
        Header: "Sex",
        accessor: "gender",
      },
      {
        Header: "Status",
        accessor: "isVerified",
      },
    ],
    []
  );

  return (
    <MainLayout title='Overview'>
      <div className='py-6 px-3 md:px-6 font-commiss'>
        <div className=' font-bold font-commiss text-center sm:text-left '>
          Good {time}, {user?.userInfo?.name.split(" ")[0]}
        </div>

        {/* Link for organisation to signup employees */}
        <div className='w-full h-[50px] rounded-lg dropDownShadow bg-gradient-to-r from-[#FF9A9A] via-[#FF9A9A] to-[#0E9CC4] mt-5 px-2 sm:px-10 flex items-center justify-between font-semibold text-[14px] sm:text-base text-gray-700'>
          {user?.orgID && user?.org?.company_name
            ? `${orgURL}/org/${user?.org?.company_name?.replace(/\s+/g, "-")}-${
                user?.orgID
              }`
            : null}
          <BiCopy
            size={20}
            className='cursor-pointer'
            onClick={() => {
              navigator.clipboard.writeText(
                `${orgURL}/org/${user?.org?.company_name?.replace(
                  /\s+/g,
                  "-"
                )}-${user?.orgID}`
              );
              toast("Link copied to clipboard", { type: "success" });
            }}
          />
        </div>

        <div className='flex flex-col gap-y-10 sm:flex-row items-center sm:justify-between w-full mt-14'>
          <OverviewCard
            title={overviewData[0].title}
            info={overviewData[0].info}
            number={employee?.totalEmployees?.length}
          />
          <OverviewCardTwo
            title={overviewData[1].title}
            info={overviewData[1].info}
            number={employee?.employeeConsultations?.length || 0}
          />
          <OverviewCard
            title={overviewData[2].title}
            info={overviewData[2].info}
            number={employee?.allVerified}
          />
        </div>

        {/* Overview table */}
        <div className='mt-10'>
          {data_all ? (
            <OverviewTable data={data_all} columns={columns} />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default Overview;

const OverviewCard = ({ title, info, number }: OverviewData): JSX.Element => {
  return (
    <div className='w-[255px] h-[140px]  flex flex-col justify-between overview-card px-4 py-6 rounded-lg overview-cards'>
      <div
        className={`self-end min-w-[80px] h-[30px] flex items-center justify-evenly border-2 rounded-xl ${
          number < 0
            ? "bg-[#F9F4DA] border-[#E0CB61]"
            : "bg-[#F7FFF7] border-[#BDEECB]"
        } `}>
        <div
          className={`${number < 0 ? "text-[#9F870D]" : "text-[#346710]"}  `}>
          {number}
        </div>
      </div>

      <div className='mt-'>
        <div className='font-semibold text-[14px]'>{title}</div>
        <div className='text-[#ACACAC] text-[12px] mt-1'>{info}</div>
      </div>
    </div>
  );
};

const OverviewCardTwo = ({
  title,
  info,
  number,
}: OverviewData): JSX.Element => {
  return (
    <div className='w-[255px] h-[140px]  flex flex-col justify-between overview-card px-4 py-6 rounded-lg overview-cards'>
      <div className='flex justify-between'>
        <div
          className={`min-w-[80px] h-[30px] flex items-center justify-evenly border-2 rounded-xl ${"bg-[#F7FFF7] border-[#BDEECB]"} `}>
          <div className={`${"text-[#346710]"}  `}>&#8358;{number * 40}</div>
        </div>

        <div
          className={`min-w-[70px] h-[30px] flex items-center justify-evenly border-2 rounded-xl ${"bg-[#F7FFF7] border-[#BDEECB]"} `}>
          <div className={`${"text-[#346710]"}  `}>{number}</div>
        </div>
      </div>

      <div className='mt-'>
        <div className='font-semibold text-[14px]'>{title}</div>
        <div className='text-[#ACACAC] text-[12px] mt-1'>{`${info} ${
          moment().format("LL").split(" ")[0]
        }`}</div>
      </div>
    </div>
  );
};
