import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import { MantineProvider } from "@mantine/core";
import { EmployeeProvider } from "./context/employeeContext";
import { SubscriptionProvider } from "./context/subscriptionContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SubscriptionProvider>
        <EmployeeProvider>
          <UserProvider>
            <MantineProvider withGlobalStyles withNormalizeCSS>
              <App />
            </MantineProvider>
          </UserProvider>
        </EmployeeProvider>
      </SubscriptionProvider>
    </BrowserRouter>
  </React.StrictMode>
);
